import React from 'react';
import './ComingSoon.css';
import logo from '../assets/img/logo.png';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <img src={logo} alt="Logo der Hausmeisterdienst Firma" className="logo" />
            <h4>Hausmeisterdienste für Ludwigsburg & Umgebung<br />Inh. Herbert Griebenow</h4>
            <div className="contact-info">
                <p>Tel.: <a href="tel:+491794361340">0179 4361340</a></p>
                <p>E-Mail: <a href="mailto:hausundhofhg@gmail.com">hausundhofhg@gmail.com</a></p>
            </div>
            <div className="services">
                <h2>Unsere Leistungen:</h2>
                <ul>
                    <li>Kehrwoche</li>
                    <li>Außenanlagenpflege</li>
                    <li>Mülltonnen stellen</li>
                    <li>Einheitliche Klingel- und Briefkastenschilder (Visitenkarte des Hauses)</li>
                    <li>Kleinreparaturen</li>
                    <li>Maschendrahtzaun (Reparatur und Neuerrichtung)</li>
                </ul>
            </div>
        </div>
    );
};

export default ComingSoon;
